import React from "react";
import colors from "../utils/colors";
import SubSection from "../components/SubSection";
import styled from "styled-components";
import { windowSize } from "../utils/responsive";

const UserConfirmationResult = ({ title, subTitle, icon, showRetryButton }) =>(
    <section>
      <SubSection style={styles.SubSection}>
        <TitleContainer>
          {title}
        </TitleContainer>
    
        <SubtitleContainer>
          {subTitle}
        </SubtitleContainer>
    
        <EmailIconContainer
          src={icon}
        />
          
        { showRetryButton &&( 
          <button style={styles.RetryButton} onClick={ refreshPage }>
            Verify email adress again
          </button>
        )}
    </SubSection>
  </section>
);

const refreshPage = () => (
  window.location.reload()
)

const TitleContainer = styled.div`
    text-align: center;
    color: ${colors.grey90};
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 29px;
    line-height: 32px;
    letter-spacing: -0.02em;
    margin-top: 0;
    @media (max-width: ${windowSize.medium}px) {
      font-size: 16px;  
    }
`;

const SubtitleContainer = styled.div`
  text-align: center;
  color: ${colors.grey70};
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.02em;
  margin-top: 8px;
  @media (max-width: ${windowSize.medium}px) {
    font-size: 14px;  
  }
`;

const styles = {
  ContactInfo: {
    display: "flex",
    flexDirection: "column",
    justifyContent:"flex-start",
    alignItems: "flex-start",
    paddingRight: 25
  },
  ContactBold: {
    color: colors.black,
    fontWeight: "bold"
  },
  RetryButton: {
    padding: "8px 24px",
    width: "252px",
    height: "48px",
    display: "flex",
    justifyContent: "center",
    margin: "55px 0px 10px",
    background: colors.blue700,
    borderRadius: "8px",
    border: "none",
    cursor: "pointer",
    fontFamily: 'Inter',
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "32px",
    color: colors.white,
  },
  SubSection: {
    flexDirection: "column", 
    justifyContent: "center", 
    alignItems: "center", 
    marginTop:"0", 
    marginBottom: "0", 
    minHeight: "calc(100vh - 321px)",
    maxWidth: "100%",
    backgroundColor: colors.white
  }
}

const EmailIconContainer = styled.img`
  width: 135px;
  height: 135px;
  text-align: center;
  margin-top: 48px;
`

export default UserConfirmationResult;
