import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import UserService from "../services/UserService";
import UserConfirmationResult from "../components/UserConfirmationResult";
import EmailEnvelopeSVG from "../images/icons/email-envelope.svg";
import EmailEnvelopeGraySVG from "../images/icons/email-envelope-gray.svg";
import SEO from "../components/SEO";
import Analytics from "../utils/tracking";
import {
  osName,
  osVersion,
  browserName,
  mobileModel,
  mobileVendor,
  browserVersion
} from "react-device-detect";

const confirmUser = () => {
  const params = new URLSearchParams(document.location.search);
  const code = params.get("code");

  return UserService.confirmUser(code);
}

async function dispatchTrack(state) {
  // Send data to Segment
  await Analytics.track("User confirmation", {
    osName: osName,
    osVersion: osVersion,
    browserName: browserName,
    browserVersion: browserVersion,
    mobileVendor: mobileVendor,
    mobileModel: mobileModel,
    state: state,
    referrerUrl: document.referrer || null
  });
}

const UserConfirmation = () => {
  const [error, setError] = useState(false);
  const [codeValidated, setCodeValidated] = useState(false);

  useEffect(() => {
    dispatchTrack("requested"); 

    confirmUser()
      .catch(function (error) {
        setError(true);
      })
      .then(function () {
        setCodeValidated(true);
        dispatchTrack(error ? "error": "success"); 
      });
    }, [])

  if (!codeValidated) {
    return (
      <></>
    )
  }

  return (
    <Layout>
      <SEO
        title="User Confirmation"
        index={false} 
        follow={false}
      />
      {!error ? (
        <UserConfirmationResult title="Your email is now verified" subTitle="Open the app and continue with the creation of your account" icon={EmailEnvelopeSVG} showRetryButton={error}/>
      ):
      (
        <UserConfirmationResult title="Something went wrong" subTitle="An error has occurred. Please verify your email adress again" icon={EmailEnvelopeGraySVG} showRetryButton={error}/>
      )}
    </Layout>
  );

} ;

export default UserConfirmation;
