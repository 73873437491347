import { GetEnvConfig } from "./config.js";

const envConfig = GetEnvConfig();

const Endpoints = {

  CONFIRM_USER: {
    URL: `/api/v2/users/confirm`
  }
}

export default Endpoints
