const environments = {
  develop: {
    apiHost: "https://api-develop.stockperks.com",
    //in ms
    timeout: 2000
  },
  stg: {
    apiHost: "https://api-stg.stockperks.com",
    //in ms
    timeout: 2000
  },
  prod: {
    apiHost: "https://api.stockperks.com",
    //in ms
    timeout: 2000
  }
}

export function GetEnvConfig(){
  switch (env) {
    case 'stg':
      return environments.stg;
    case 'production':
      return environments.prod;
    default:
      return environments.develop;
  }
}

const env = process.env.NODE_ENV || "develop";