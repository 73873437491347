import Endpoints from "../constants/endpoints"
import apiClient from '../utils/httpUtils';
import HttpCodes from "../utils/httpUtils";

const UserService = {
  confirmUser: async (code) => {
    const queryParams = new URLSearchParams({ code }).toString()

    try {
        const response = await apiClient.get(`${Endpoints.CONFIRM_USER.URL}?${queryParams}`)
        return response.data
    }
    catch (error) {
        return Promise.reject(error)
    }
}
}

export default UserService
