import axios from "axios";
import { v4 as uuidv4 } from 'uuid';  
import { GetEnvConfig } from '../constants/config.js';

const envConfig = GetEnvConfig();

const apiClient = axios.create({
    baseURL: envConfig.apiHost,
    headers: {
      'Accept': 'application/json',
      'Stockperks-Client-Platform': 'website-v2',
      'Stockperks-Client-Version': '0.0.1'
    }
});

apiClient.interceptors.request.use(function (config) {
    config.headers["Stockperks-Trace-ID"] = uuidv4();
    return config;
    }, function (error) {
    return Promise.reject(error);
});

export default apiClient;